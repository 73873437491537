import React from "react"
import BlogLayout from "../../components/Blog/BlogLayout"
import BlogSixteen from "../../components/Blog/BlogsList/BlogSixteen"
const BlogContent = () => {
  return (
    <BlogLayout>
      <BlogSixteen />
    </BlogLayout>
  )
}

export default BlogContent
